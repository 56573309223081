import React, { useState } from "react";

const faq = [
  {
    question: "What is BytFarm?",
    answer:
      "BytFarm is a crypto investment platform that allows users to profit from cryptocurrency mining.",
  },
  {
    question: "How does BytFarm work?",
    answer:
      "BytFarm provides access to cutting-edge mining equipment, which users can invest in to mine cryptocurrencies and earn passive income.",
  },
  {
    question: "What cryptocurrencies can I mine on BytFarm?",
    answer:
      "You can mine popular cryptocurrencies like Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC), and more on BytFarm.",
  },
  {
    question: "Is BytFarm secure?",
    answer:
      "Yes, BytFarm prioritizes security and uses advanced encryption methods to protect user data and investments.",
  },
  {
    question: "Do I need technical expertise to use BytFarm?",
    answer:
      "No, BytFarm offers a user-friendly interface that makes it easy for both beginners and experienced users to navigate and manage their investments.",
  },
  {
    question: "How can I contact BytFarm for support?",
    answer:
      "You can reach out to our expert support team through email at support@bytfarm.com or via our contact form on the website.",
  },
  {
    question: "Can I track my investments and mining activities on BytFarm?",
    answer:
      "Yes, BytFarm provides real-time analytics and reporting tools that allow users to track their investments and mining activities with full visibility.",
  },
  {
    question: "Are there any fees for using BytFarm?",
    answer:
      "BytFarm charges a small management fee to cover operational costs. The fee structure is transparent and detailed on our platform.",
  },
  {
    question: "How can I get started with BytFarm?",
    answer:
      "Simply sign up on our website, choose your investment plan, and start mining cryptocurrencies to earn passive income.",
  },
  {
    question: "Is BytFarm available worldwide?",
    answer:
      "Yes, BytFarm is accessible to users from around the world, allowing anyone with an internet connection to participate in crypto mining and investment.",
  },
];



const Faq = () => {
  const [openItems, setOpenItems] = useState(new Array(faq.length).fill(false));

  const toggleItem = (index) => {
    const updatedOpenItems = openItems.map((item, i) =>
      i === index ? !item : false
    );
    setOpenItems(updatedOpenItems);
  };

  return (
    <div className="w-full min-h-screen flex flex-col justify-center items-center p-5 gap-5 pt-[19vh] bg-transparent">
      <h2
        data-aos="fade-up"
        className="font-semibold text-5xl max-lg:text-3xl text-white"
      >
        FAQ's
      </h2>
      {faq.map((item, index) => (
        <div
          onClick={() => toggleItem(index)}
          key={index}
          className={`p-2 w-full py-2 z-10 cursor-pointer ${
            openItems[index]
              ? "border-primary border-l-4"
              : "border-transparent"
          } transition-all ease-in-out`}
        >
          <div className="w-full flex items-center justify-between text-white font-semibold text-lg">
            <h4 className="w-5/6 ">{item.question}</h4>
            {openItems[index] ? "-" : "+"}
          </div>
          <p
            className={`text-primary ${
              openItems[index] ? "h-max" : "h-0 opacity-0"
            } transition-all ease-in-out text-xs`}
          >
            {item.answer}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Faq;
