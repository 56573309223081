import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../apis/api";
import Modal from "./Modal";

const DeleteAnotherUser = ({id, setLoadUser, profile}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const deleteUser = async (e) => {
    e.preventDefault();
    setLoadUser(true);
    try {
      const res = await api.delete(`/delete-users/${id}`);
      setLoadUser(false);
      navigate("/restricted/admin/users");
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setLoadUser(false);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    };
    return (
      <>
        <button
          onClick={openModal}
          className="px-2 text-xs text-primary ease-in-out transition-all hover:text-primary/50"
        >
          Delete profile
        </button>
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <div className="border border-primary/10 w-full p-5 bg-black shadow-md flex flex-col gap-2 items-center z-10">
            <h1 className="text-xl font-semibold text-primary">
              Delete account
            </h1>
            <p className="text-primary text-xs">
              Are you sure you want to delete your
              account?
            </p>
            <div className="w-full flex gap-5">
              <button
                onClick={closeModal}
                className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out"
              >
                No
              </button>
              <form onSubmit={deleteUser} className="w-full">
                <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
                  Yes
                </button>
              </form>
            </div>
          </div>
        </Modal>
      </>
    );
};

export default DeleteAnotherUser;
