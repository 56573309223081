import React from "react";
import { Link } from "react-router-dom";

const ManageAddress = () => {
  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">
            Manage Addresses
          </span>
        </div>
        {/*body*/}
        <div className="w-full flex flex-col justify-center">
          <div className="w-full gap-2 grid grid-cols-2 lg:grid-cols-4 text-sm">
            <Link
              className="w-full bg-primary py-2 flex justify-center items-center transition-all ease-in-out hover:bg-primary/50 hover:scale-95"
              to={`/restricted-page/admin/btc`}
            >
              Bitcoin
            </Link>
            <Link
              className="w-full bg-primary py-2 flex justify-center items-center transition-all ease-in-out hover:bg-primary/50 hover:scale-95"
              to={`/restricted-page/admin/eth`}
            >
              Ethereum
            </Link>
            <Link
              className="w-full bg-primary py-2 flex justify-center items-center transition-all ease-in-out hover:bg-primary/50 hover:scale-95"
              to={`/restricted-page/admin/usdt`}
            >
              Usd Tether
            </Link>
            <Link
              className="w-full bg-primary py-2 flex justify-center items-center transition-all ease-in-out hover:bg-primary/50 hover:scale-95"
              to={`/restricted-page/admin/lite`}
            >
              Litecoin
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAddress;
