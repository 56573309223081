import React from "react";
import coin from "../backgrounds/ss.png";

const Section2 = () => {
  return (
    <div className="w-full h-screen max-lg:h-fit bg-why bg-cover bg-center">
      <div className="w-full h-screen bg-black/20 backdrop-blur-3xl flex max-lg:flex-col p-5 gap-5  pt-[10vh]">
        <div className="w-full h-full flex flex-col justify-center gap-5">
          <h2
            data-aos="fade-up"
            className="font-semibold text-3xl max-lg:text-2xl text-white leading-normal"
          >
            Join BytFarm Today! <br />
            <span className="text-primary">
              Ready to dive into the world of <br /> crypto investment and
              mining?
            </span>
          </h2>
          <p className="text-sm text-white text-left tracking-wide">
            Sign up with BytFarm today and unlock a world of opportunities.
            Start earning passive income, diversify your investment portfolio,
            and join the future of finance with BytFarm.
          </p>
        </div>
        <div className="w-full h-full flex items-center justify-center">
          <img data-aos="fade-left" src={coin} alt="" className="h-[75vh] lg:h-[90vh]" />
        </div>
      </div>
    </div>
  );
};

export default Section2;
