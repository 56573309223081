import React from 'react'

const ContactPage = () => {
  return (
    <div className="w-full min-h-screen flex flex-col gap-4 bg-gradient-to-b from-black to-black/80 relative px-10 pt-[9vh]">
      <h2
        data-aos="fade-up"
        className="font-semibold text-5xl max-lg:text-3xl text-primary w-full my-20 z-10"
      >
        Contact Us
      </h2>
      <h3 className="font-semibold text-white">Get in touch with us via:</h3>
      <div className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out">
        <i className="fa-solid fa-envelope text-primary"></i>
        <span className="text-white">support@bytfarm.com</span>
      </div>
      <div className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out">
        <i className="fa-solid fa-location-dot text-primary"></i>
        <span className="text-white">
          125 SEAVIEW AVE JERSEY CITY NJ 07305-2483 USA
        </span>
      </div>
      <div className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out">
        <i className="fa-solid fa-phone text-primary"></i>
        <span className="text-white">+1 8188619485</span>
      </div>
    </div>
  );
}

export default ContactPage