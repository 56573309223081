import React from "react";
import { useNavigate } from "react-router-dom";
import useFetchPlan from "../../hooks/useFetchPlan";
import CoinBackground from "../backgrounds/CoinBackground";

const Section4 = () => {
  const { plan } = useFetchPlan();
  const navigate = useNavigate();
  const token = !!localStorage.getItem("access_token");
  const getStarted = () => {
    if (token === true) {
      navigate("/make-deposit");
    } else {
      navigate("/signup");
    }
  };
  return (
    <div className="w-full h-fit flex flex-col justify-center items-center p-5 gap-5 pt-[10vh] bg-transparent">
      <CoinBackground />
      <h2
        data-aos="fade-up"
        className="font-semibold text-5xl max-lg:text-3xl text-white"
      >
        Our Plan Offers
      </h2>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 p-5 gap-5">
        {plan.map((item) => {
          return (
            <div
              key={item.id}
              data-aos="fade-left"
              className="w-full bg-gradient-to-r from-black to-primary/10 border border-primary p-5 shadow flex flex-col items-center gap-10 z-10"
            >
              <h3 className="text-xl font-semibold text-primary capitalize">
                {item.title} plan
              </h3>
              <div className="w-full flex flex-col text-xs text-white">
                <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                  <span>Return on Investment</span>
                  <span>{item.roi}%</span>
                </div>
                <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                  <span>Duration (in days)</span>
                  <span>{item.duration} days</span>
                </div>
                <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                  <span>Total return</span>
                  <span>
                    {item.duration * item.roi}%
                  </span>
                </div>
              </div>
              <div className="w-full flex justify-center gap-2 items-center text-base lg:text-sm text-primary font-semibold">
                <span>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(item.min_amount)}
                </span>
                <span>-</span>
                <span>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(item.max_amount)}
                </span>
              </div>
              <button
                onClick={getStarted}
                className="p-1.5 flex justify-center w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out z-20"
              >
                Get started
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Section4;
