import React, { useEffect, useState } from "react";
import api from "../../apis/api";
import Spinner from "../../components/spinners/Spinner";

const Plans = () => {
  const [plan, setPlan] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPlan = async () => {
    setLoading(true);
    try {
      const res = await api.get("/show-user-plan");
      setPlan(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPlan();
  }, []);

  const calculateProgress = (duration, initialDuration) => {
    const percentage = (duration / initialDuration) * 100;
    return Math.round(percentage);
  };

    if (loading === true) {
      return (
        <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
          <div className="w-full h-full flex flex-col gap-5 p-5">
            <div className="w-full h-16 flex border-b border-primary items-center">
              <span className="text-2xl font-bold  text-primary">Plans</span>
            </div>
            {/*body*/}
            <div className="w-full flex justify-center my-10">
              <Spinner />
            </div>
          </div>
        </div>
      );
    }

    if (plan.length === 0) {
      return (
        <div className="w-full min-h-screen bg-gradient-to-b from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
          <div className="w-full h-full flex flex-col gap-5 p-5">
            <div className="w-full h-16 flex border-b border-primary items-center">
              <span className="text-2xl font-bold  text-primary">Plans</span>
            </div>
            {/*body*/}
            <div className="w-full flex justify-center my-10 text-primary text-xs">
              No active plans.
            </div>
          </div>
        </div>
      );
    }

  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">Plans</span>
        </div>
        {/*body*/}
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 ">
          {plan.map((item) => {
            const progress = calculateProgress(
              item.duration,
              item.initial_duration
            );
            return (
              <div
                key={item.id}
                className="w-full bg-gradient-to-r from-black to-primary/10 border border-primary p-5 shadow flex flex-col items-center gap-5"
              >
                <h3 className="text-xl font-semibold text-primary capitalize">
                  {item.title} plan
                </h3>
                <div className="w-full flex flex-col text-xss max-lg:text-xs text-white">
                  <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                    <span>Plan ID</span>
                    <span>{item.reference_number}</span>
                  </div>
                  <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                    <span>Return on Investment</span>
                    <span>{item.roi}%</span>
                  </div>
                  <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                    <span>Duration left</span>
                    <span>{item.duration} days</span>
                  </div>
                  <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                    <span>Inital duration</span>
                    <span>{item.initial_duration} days</span>
                  </div>
                  <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                    <span>Amount</span>
                    <span>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(item.amount)}
                    </span>
                  </div>
                  <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                    <span>Total return</span>
                    <div className="flex gap-1 items-center">
                      <span>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(
                          ((item.initial_duration * item.roi) / 100) *
                            item.amount
                        )}
                      </span>
                      
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                    <span>Daily return</span>
                    <span>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(item.returns)}
                    </span>
                  </div>
                  <div className="relative w-full h-3 bg-primary/10 mt-4 rounded-3xl">
                    <div
                      className="absolute h-full bg-primary rounded-3xl"
                      style={{ width: `${progress}%` }}
                    ></div>
                    <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center">
                      <span className="text-xxs text-white">{progress}%</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Plans;
