import React from "react";
import CoinBackground from "../backgrounds/CoinBackground";

const items = [
  {
    title: "Profitable Crypto Mining",
    text: "We provide access to cutting-edge mining equipment, allowing you to mine popular cryptocurrencies like Bitcoin, Ethereum, and more. Earn passive income by harnessing the power of blockchain technology.",
    icon: "fa-solid fa-arrow-trend-up",
  },
  {
    title: "Transparent Operations",
    text: "With BytFarm, transparency is key. Our platform offers real-time analytics and reporting, so you can track your investments and mining activities with full visibility",
    icon: "fa-regular fa-eye",
  },
  {
    title: "Secure and Reliable",
    text: "Rest assured that your investments and data are safe with us. We prioritize security and use advanced encryption methods to protect your assets and personal information.",
    icon: "fa-solid fa-lock",
  },
  {
    title: "User-Friendly Interface",
    text: "Whether you're a seasoned crypto investor or just starting, our intuitive interface makes it easy to navigate and manage your investments. No technical expertise required!",
    icon: "fa-solid fa-user-check",
  },
  {
    title: "Expert Support",
    text: "Our team of experienced professionals is here to support you every step of the way. From setting up your mining operations to optimizing your investment strategy, we're here to help you succeed.",
    icon: "fa-solid fa-headset",
  },
];

const Section3 = () => {
  return (
    <div className="w-full  min-h-screen flex flex-col justify-center items-center p-5 gap-5 pt-[19vh] bg-transparent">
      <CoinBackground />
      <h2
        data-aos="fade-up"
        className="font-semibold text-5xl max-lg:text-3xl text-white"
      >
        Why Choose Us?
      </h2>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 p-5 gap-5">
        {items.map((item) => {
          return (
            <div
              key={item.title}
              data-aos="fade-right"
              className="w-full border border-primary p-8 shadow z-10 shadow-primary/10 bg-gradient-to-r from-black to-primary/10 flex flex-col gap-2 group hover:-translate-y-2 ease-in-out transition-all"
            >
              <div className="w-full flex gap-2 justify-start items-center text-primary text-2xl">
                <i className={item.icon}></i>
                <span className="font-medium">{item.title}</span>
              </div>
              <p className="text-white font-light text-pretty">{item.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Section3;
