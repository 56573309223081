import React from "react";
import { Link } from "react-router-dom";
import CoinBackground from "../backgrounds/CoinBackground";

const Section1 = () => {
  return (
    <div className="w-full pt-[10vh] h-screen bg-black flex flex-col justify-center items-center gap-5 px-5 relative">
      <CoinBackground />
      <div
        data-aos="fade-up"
        className="flex w-full flex-col items-center font-bold text-5xl max-md:text-3xl"
      >
        <h1 className="text-white">Welcome to BytFarm</h1>
        <h1 className="text-primary text-lg  max-md:text-3xl">
          Your Gateway to Crypto <br className="lg:hidden"/> Investment and Mining
        </h1>
      </div>
      <p
        data-aos="fade-up"
        className="w-5/6 md:2/3 lg:w-1/2 text-white text-center text-sm"
      >
        At BytFarm, we believe in the transformative power of cryptocurrency and
        the immense opportunities it offers for financial growth. Our platform
        is designed to empower individuals and businesses to participate in the
        exciting world of crypto mining and investment with ease and confidence.
      </p>
      <div className="flex gap-5">
        <Link
          to={`/Signup`}
          className="p-1.5 flex justify-center w-32 bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out z-20"
        >
          Get started
        </Link>
      </div>
    </div>
  );
};

export default Section1;
