import React from "react";
import Faq from "../../components/home/Faq";
import Footer from "../../components/home/Footer";
import Section1 from "../../components/home/Section1";
import Section2 from "../../components/home/Section2";
import Section3 from "../../components/home/Section3";
import Section4 from "../../components/home/Section4";
import Section5 from "../../components/home/Section5";
import Section7 from "../../components/home/Section7";

const Home = () => {
  return (
    <div className="w-full flex flex-col bg-gradient-to-b from-black to-black/90 relative bg-fixed overflow-hidden">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Faq />
      <Section7 />
      <Footer/>
    </div>
  );
};

export default Home;
