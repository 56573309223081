import React from 'react'
import Footer from '../../components/home/Footer';

const Privacy = () => {
  return (
    <>
      <div className="w-full min-h-screen flex flex-col gap-4 bg-gradient-to-b from-black to-black/80 relative px-10 pt-[9vh]">
        <h2
          data-aos="fade-up"
          className="font-semibold text-5xl max-lg:text-3xl text-primary w-full my-20 z-10"
        >
          Privacy Policy
        </h2>
        <p className="text-sm text-white text-left tracking-wide z-10">
          At BytFarm, we are committed to protecting your privacy and ensuring
          the security of your personal information. This Privacy Policy
          outlines how we collect, use, and safeguard the data you provide to
          us.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Information We Collect
        </h5>
        <ul data-aos="fade-right" className="list-decimal list-inside">
          <li className="text-sm text-white text-left tracking-wide z-10">
            <span className="text-primary font-medium">
              Personal Information:
            </span>{" "}
            When you create an account or use our services, we may collect
            personal information such as your name, email address, contact
            details, and payment information.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            <span className="text-primary font-medium">Usage Data:</span> We may
            collect information about how you interact with our website,
            including IP address, browser type, pages visited, and time spent on
            each page.
          </li>
        </ul>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          How We Use Your Information
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          We use the information we collect for the following purposes:
        </p>
        <ul data-aos="fade-right" className="list-decimal list-inside">
          <li className="text-sm text-white text-left tracking-wide z-10">
            To provide and improve our services, including customer support and
            technical assistance.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            To personalize your experience and customize content and offerings
            based on your preferences.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            To process transactions and manage your account.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            To communicate with you regarding updates, promotions, and important
            notices.
          </li>
        </ul>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Data Security
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          We implement industry-standard security measures to protect your
          personal information from unauthorized access, disclosure, alteration,
          or destruction. However, no method of transmission over the internet
          or electronic storage is 100% secure, and we cannot guarantee absolute
          security.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Sharing of Information
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          We may share your information with third-party service providers, such
          as payment processors and analytics providers, to facilitate our
          services and improve user experience. We do not sell or rent your
          personal information to third parties for marketing purposes.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Cookies and Tracking Technologies
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          BytFarm uses cookies and similar tracking technologies to enhance your
          browsing experience, analyze usage patterns, and deliver targeted
          advertisements. You can control cookies through your browser settings
          and opt-out of targeted advertising by adjusting your preferences.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Your Rights
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          You have the right to access, update, and delete your personal
          information stored on our platform. You may also request information
          about the data we hold about you and exercise your rights under
          applicable data protection laws.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Changes to Privacy Policy
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          BytFarm reserves the right to update or modify this Privacy Policy at
          any time. We will notify you of any changes by posting the revised
          policy on our website. Your continued use of our services after such
          changes constitutes acceptance of the updated policy.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Contact Us
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10 mb-10">
          If you have any questions, concerns, or requests regarding your
          privacy or this Privacy Policy, please contact us at
          support@bytfarm.com.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default Privacy