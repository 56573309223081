import React from "react";
import Footer from "../../components/home/Footer";

const Terms = () => {
  return (
    <>
      <div className="w-full min-h-screen flex flex-col gap-4 bg-gradient-to-b from-black to-black/80 relative px-10 pt-[9vh]">
        <h2
          data-aos="fade-up"
          className="font-semibold text-5xl max-lg:text-3xl text-primary w-full my-20 z-10"
        >
          Terms & Conditions
        </h2>
        <p className="text-sm text-white text-left tracking-wide z-10">
          Welcome to BytFarm! These Terms and Conditions outline the rules and
          regulations for the use of our website.
        </p>
        <p className="text-sm text-white text-left tracking-wide z-10">
          By accessing this website, we assume you accept these terms and
          conditions. Do not continue to use BytFarm if you do not agree to all
          of the terms and conditions stated on this page.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Intellectual Property
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          All content on this website, including but not limited to text,
          graphics, logos, images, and software, is the property of BytFarm or
          its licensors and is protected by copyright laws. You may not
          reproduce, modify, distribute, or use any content from this website
          without explicit permission.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          User Accounts
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          To access certain features of BytFarm, you may be required to create a
          user account. You are responsible for maintaining the confidentiality
          of your account and password and for restricting access to your
          account. You agree to accept responsibility for all activities that
          occur under your account.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Acceptable Use
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          You agree to use BytFarm for lawful purposes only and not to engage in
          any activity that violates local, national, or international laws or
          regulations. Prohibited activities include but are not limited to:
        </p>
        <ul data-aos="fade-right" className="list-decimal list-inside">
          <li className="text-sm text-white text-left tracking-wide z-10">
            Engaging in any form of hacking, data mining, or unauthorized data
            collection.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            Posting or transmitting any unlawful, threatening, defamatory,
            obscene, or offensive content.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            Attempting to gain unauthorized access to our systems or networks.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            Impersonating any person or entity or falsely representing your
            affiliation with BytFarm.
          </li>
        </ul>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Privacy Policy
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          Your privacy is important to us. Please refer to our Privacy Policy
          for information on how we collect, use, and protect your personal
          data.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Limitation of Liability
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          BytFarm and its affiliates shall not be liable for any direct,
          indirect, incidental, special, or consequential damages arising out of
          or in any way connected with your use of this website or the
          information provided herein.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Changes to Terms and Conditions
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          BytFarm reserves the right to modify or revise these Terms and
          Conditions at any time without prior notice. Your continued use of the
          website after any such changes constitutes acceptance of the new
          terms.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Contact Us
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10 mb-10">
          If you have any questions or concerns about these Terms and
          Conditions, please contact us at support@bytfarm.com.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
