import React, { useState } from "react";
import api from "../../apis/api";
import PlanModal from "../../components/modals/PlanModal";
import useFetchPlan from "../../hooks/useFetchPlan";
import { toast } from "react-toastify";
import Spinner from "../../components/spinners/Spinner";
import EditPlanModal from "../../components/modals/EditPlanModal";
import { Link } from "react-router-dom";

const AdminPlan = () => {
  const { plan, loadPlan } = useFetchPlan();
  const [loading, setLoading] = useState(false);
  const deleteItem = async (e) => {
    e.preventDefault();
    setLoading(true);
    const id = e.target.elements["id"].value;
    try {
      const res = await api.delete(`/delete-plan/${id}`);
      toast.success(res.data.message);
      window.location.reload();
      // setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  if (loading === true || loadPlan === true) {
    return (
      <div className="w-full min-h-screen bg-gradient-to-b from-black to-black/90 pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Manage Plans
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">Manage Plans</span>
        </div>
        {/*body*/}
        <div className="w-full flex justify-end">
          <PlanModal />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 ">
          {plan.map((item) => {
            return (
              <div
                key={item.id}
                className="w-full bg-gradient-to-r from-black to-primary/10 border border-primary p-5 shadow flex flex-col items-center gap-5"
              >
                <h3 className="text-xl font-semibold text-primary capitalize">
                  {item.title} plan
                </h3>
                <div className="w-full flex flex-col text-xs text-white">
                  <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                    <span>Return on Investment</span>
                    <span>{item.roi}%</span>
                  </div>
                  <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                    <span>Duration (in days)</span>
                    <span>{item.duration} days</span>
                  </div>
                  <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                    <span>Total return</span>
                    <span>
                      {item.duration * item.roi}%
                    </span>
                  </div>
                </div>
                <div className="w-full flex justify-center gap-2 items-center text-base lg:text-sm text-primary font-semibold">
                  <span>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(item.min_amount)}
                  </span>
                  <span>-</span>
                  <span>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(item.max_amount)}
                  </span>
                </div>
                <div className="w-full flex flex-col gap-2 text-sm">
                  <EditPlanModal id={item.id} />
                  <form className="w-full" onSubmit={deleteItem}>
                    <input type="hidden" name="id" defaultValue={item.id} />
                    <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
                      Delete plan
                    </button>
                  </form>
                  <Link
                    to={`/restricted-page/admin/plan/${item.id}`}
                    className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out text-center"
                  >
                    Users on this plan
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminPlan;
