import React, { useEffect, useState } from "react";
import api from "../../apis/api";
import Modal from "../../components/modals/Modal";
import Spinner from "../../components/spinners/Spinner";
import { toast } from "react-toastify";

const AdminWithdrawal = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeposit, setSelectedDeposit] = useState(null);
  const [copy, setCopy] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const copyToClipboard = () => {
    const address = selectedDeposit.address;
    navigator.clipboard.writeText(address);
    toast.info("Copied to clipboard");
    setCopy(true);
  };

  const fetchWithdrawals = async () => {
    setLoading(true);
    try {
      const res = await api.get("/transaction/withdrawal");
      setWithdrawals(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWithdrawals();
  }, []);

  const confirmStatus = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post(`/transaction/status/${selectedDeposit.id}`);
      setLoading(false);
      toast.success(res.data.message);
      closeModal();
      fetchWithdrawals();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const failStatus = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post(`/transaction/failed/${selectedDeposit.id}`);
      setLoading(false);
      toast.success(res.data.message);
      closeModal();
      fetchWithdrawals();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const openModal = (deposit) => {
    setSelectedDeposit(deposit);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCopy(false)
  };

  function getStatusDot(status) {
    let dotColor = "";

    if (status === "pending") {
      dotColor = "#fb923c";
    } else if (status === "confirmed") {
      dotColor = "#22c55e";
    } else if (status === "failed") {
      dotColor = "#ef4444";
    }

    return (
      <div className="flex items-center gap-0.5">
        <div
          style={{
            backgroundColor: dotColor,
          }}
          className="rounded-3xl w-1.5 h-1.5"
        ></div>
        <span className="capitalize text-xss text-primary">{status}</span>
      </div>
    );
  }

  const getDateAndTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const date = dateTime.toLocaleDateString();
    const time = dateTime.toLocaleTimeString();

    return { date, time };
  };

  if (loading === true) {
    return (
      <div className="w-full min-h-screen bg-gradient-to-b from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Manage Withdrawals
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">
            Manage Withdrawals
          </span>
        </div>
        {/*body*/}
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by Transaction ID"
          className="w-full bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
        />
        <div className="w-full flex flex-col gap-1">
          {withdrawals
            .filter((transaction) => {
              return transaction.transaction_id
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            })
            .slice()
            .reverse()
            .map((item) => {
              const formattedNumber = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(item.amount);
              const { date, time } = getDateAndTime(item.created_at);
              return (
                <div
                  onClick={() => openModal(item)}
                  key={item.id}
                  className="w-full px-1 h-9 bg-black hover:bg-black/50 transition-all ease-in-out grid grid-cols-3 text-primary"
                >
                  <div className="w-full flex flex-col justify-center items-start">
                    <span className="text-xs">-{formattedNumber}</span>
                    <span className="text-xxs">
                      {date}, {time}
                    </span>
                  </div>
                  <div className="w-full flex justify-center items-center">
                    <span className="text-xs capitalize">{item.username}</span>
                  </div>
                  <div className="w-full flex flex-col justify-center items-end">
                    <span className="text-xs capitalize">{item.type}</span>
                    <span className="text-xxs">
                      {getStatusDot(item.status)}
                    </span>
                  </div>
                </div>
              );
            })}
          <Modal isOpen={isModalOpen} onClose={closeModal}>
            {selectedDeposit && (
              <div className="border border-primary/10 w-full p-5 bg-black shadow-md flex flex-col gap-2 items-center z-10">
                <h5 className="text-primary w-full text-center text-xs">
                  Withdrawal Details
                </h5>
                <h2 className="text-primary w-full text-center text-3xl font-bold">
                  -
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(selectedDeposit.amount)}
                </h2>
                <div className="w-full flex justify-center">
                  {getStatusDot(selectedDeposit.status)}
                </div>
                <div className="w-full flex justify-between text-primary text-xs items-center">
                  <span>Network</span>
                  <span className="text-xxs px-1 py-0.5 bg-primary text-black rounded cursor-pointer">
                    {selectedDeposit.payment_method}
                  </span>
                </div>
                <div className="w-full flex justify-between text-primary text-xs items-center">
                  <span>Username</span>
                  <span className="capitalize">{selectedDeposit.username}</span>
                </div>
                <div className="w-full flex justify-between text-primary text-xs items-center">
                  <span>Transaction ID</span>
                  <span className="">{selectedDeposit.transaction_id}</span>
                </div>
                <div className="w-full flex justify-between text-primary text-xs items-center">
                  <span>Date</span>
                  <span>{getDateAndTime(selectedDeposit.created_at).date}</span>
                </div>
                <div className="w-full flex justify-between text-primary text-xs items-center">
                  <span>Time</span>
                  <span>{getDateAndTime(selectedDeposit.created_at).time}</span>
                </div>
                {selectedDeposit.address === null ? (
                  ""
                ) : (
                  <div className="w-full flex justify-center text-primary text-xs">
                    <button
                      onClick={copyToClipboard}
                      className="flex justify-center items-center p-1 gap-2  text-primary"
                    >
                      <span className="text-xs md:text-xs lg:text-xxs">
                        {selectedDeposit.address}
                      </span>
                      {copy ? (
                        <i className="fa-solid fa-check"></i>
                      ) : (
                        <i className="fa-solid fa-clone"></i>
                      )}
                    </button>
                  </div>
                )}

                <div className="w-full gap-2 flex">
                  {selectedDeposit.status === "pending" ? (
                    <form onSubmit={confirmStatus} className="w-full">
                      <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
                        Confirm
                      </button>
                    </form>
                  ) : (
                    ""
                  )}
                  {selectedDeposit.status === "pending" ? (
                    <form onSubmit={failStatus} className="w-full">
                      <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
                        Reject
                      </button>
                    </form>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AdminWithdrawal;
